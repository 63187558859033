import { defineComponent } from "vue";
import { withInstall } from "@icons-vue/utils";

const NoticeTSX = defineComponent({
	name: "Notice",
	render() {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">	<path		d="M643.072 97.28c-20.48-12.8-45.568-14.848-70.656-3.072l-322.56 188.928h-158.72c-36.864 0-67.072 30.208-67.072 67.072v340.48c0 36.864 30.208 67.072 67.072 67.072h158.72l325.12 190.464c9.216 4.608 19.456 6.656 29.696 6.656 13.312 0 26.624-3.584 37.888-11.264 20.48-12.8 32.768-35.328 32.768-59.392v-727.04c0-24.576-12.288-47.104-32.256-59.904zM225.28 689.664l-133.12 1.024-1.024-338.944H225.28v337.92z m381.44 197.632l-313.344-183.808V336.896l312.32-182.784 1.536 730.112-0.512 3.072z m389.12-377.344c0 18.944-15.36 34.304-34.304 34.304h-155.648a34.304 34.304 0 0 1 0-68.608h155.648c18.944 0 34.304 15.36 34.304 34.304z m-218.624-174.08c-10.24-15.872-6.144-36.864 9.216-47.104L921.6 198.656c15.872-10.752 36.864-6.144 47.104 9.216 10.24 15.872 6.144 36.864-9.216 47.104L824.32 345.088c-5.632 4.096-12.288 5.632-18.944 5.632-10.752 0.512-21.504-4.608-28.16-14.848z m182.272 428.544c15.872 10.24 19.968 31.744 9.216 47.104-6.656 9.728-17.408 15.36-28.672 15.36-6.656 0-13.312-2.048-18.944-5.632l-135.168-90.112c-15.872-10.24-19.968-31.744-9.216-47.104 10.24-15.872 31.744-19.968 47.104-9.216l135.68 89.6z"	></path></svg>
		);
	},
});

export const Notice = withInstall(NoticeTSX);
export default Notice;
