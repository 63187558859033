import { defineComponent } from "vue";
import { withInstall } from "@icons-vue/utils";

const StockTSX = defineComponent({
	name: "Stock",
	render() {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1025 1024">	<path		d="M534.904464 6.6052l4.434165 3.197715 468.998195 383.725796c42.6362 34.919047-6.821792 98.063259-49.969626 68.985371l-4.007803-2.984534L512.349915 97.846667 171.260318 376.900593v561.220295l299.647211 0.042636a42.6362 42.6362 0 0 1 42.337746 37.647764l0.298453 4.988436a42.6362 42.6362 0 0 1-37.647764 42.337746l-4.988435 0.298453H149.942218c-33.981051 0-60.799221-26.647625-63.698482-59.520134L85.987919 958.031993V446.696052l-15.647485 12.833496c-42.6362 34.919047-94.737635-26.093354-57.729414-62.675213l3.751985-3.325624 468.998195-383.725796a42.6362 42.6362 0 0 1 49.543264-3.197715z"	></path>	<path		d="M256.532717 597.073928m42.6362 0l170.544798 0q42.6362 0 42.6362 42.636199l0 0q0 42.6362-42.6362 42.6362l-170.544798 0q-42.6362 0-42.6362-42.6362l0 0q0-42.6362 42.6362-42.636199Z"	></path>	<path		d="M256.532717 426.529129m42.6362 0l170.544798 0q42.6362 0 42.6362 42.6362l0 0q0 42.6362-42.6362 42.636199l-170.544798 0q-42.6362 0-42.6362-42.636199l0 0q0-42.6362 42.6362-42.6362Z"	></path>	<path		d="M256.532717 767.618726m42.6362 0l85.272399 0q42.6362 0 42.636199 42.636199l0 0q0 42.6362-42.636199 42.6362l-85.272399 0q-42.6362 0-42.6362-42.6362l0 0q0-42.6362 42.6362-42.636199Z"	></path>	<path		d="M810.803311 639.710127a170.544798 170.544798 0 0 1 146.924344 257.181556l53.721612 53.721611a42.6362 42.6362 0 0 1-56.237148 63.911663l-4.007802-3.58144-53.806884-53.721612A170.544798 170.544798 0 1 1 810.803311 639.710127z m0 85.272399a85.272399 85.272399 0 1 0 0 170.544798 85.272399 85.272399 0 0 0 0-170.544798z"	></path></svg>
		);
	},
});

export const Stock = withInstall(StockTSX);
export default Stock;
