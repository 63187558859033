import type { VNode } from "vue";
import type { TableColumnCtx } from "element-plus";
import type { GTableState } from "./table.state";
import type { GLayoutGridBreakPoint, GLayoutGridItemResponsive } from "@gejia-element-plus/components/layoutGrid";

export type GTableDefaultSlotsResult = {
	loading: boolean;
	searchParam: any;
	selected: boolean;
	selectedList: any[];
	selectedListIds: (string | number)[];
	indeterminateSelectedListIds: (string | number)[];
	/** @description 可能为空 */
	search?: () => void;
};

export const getTableDefaultSlots = (state: GTableState): GTableDefaultSlotsResult => {
	return {
		loading: state.loading,
		searchParam: state.searchParam,
		selected: state.selected,
		selectedList: state.selectedList,
		selectedListIds: state.selectedListIds,
		indeterminateSelectedListIds: state.indeterminateSelectedListIds,
	};
};

export type GTableEnumColumnCtx = {
	/**
	 * 选项框显示的文字
	 */
	label: string;
	/**
	 * 选项框值
	 */
	value: string | number | boolean;
	/**
	 * 显示
	 */
	show?: boolean;
	/**
	 * 是否禁用此选项
	 */
	disabled?: boolean;
	/**
	 * 为树形选择是，可以通过 children 属性指定子选项
	 */
	children?: GTableEnumColumnCtx[];
	/**
	 * 提示
	 */
	tips?: string;
	/**
	 * Tag的类型，默认 "primary"
	 */
	type?: "primary" | "success" | "info" | "warning" | "danger";
};

export type GTableEnumColumnType = string | GTableEnumColumnCtx[] | ((...args) => Promise<GTableEnumColumnCtx[]>);

export type GTableSearchColumnEl =
	| ""
	| "el-input"
	| "el-input-number"
	| "el-select"
	| "el-select-v2"
	| "el-tree-select"
	| "el-cascader"
	| "el-date-picker"
	| "el-time-picker"
	| "el-time-select"
	| "el-switch"
	| "el-slider"
	| "slot";

export type GTableSearchColumnCtx = Partial<Record<GLayoutGridBreakPoint, GLayoutGridItemResponsive>> & {
	/**
	 * 当前项搜索框的类型
	 */
	el: GTableSearchColumnEl | string;
	/**
	 * 搜索项参数，根据 element plus 官方文档来传递，该属性所有值会透传到组件
	 */
	props?: any;
	/**
	 * 当搜索项 label 不为列 label 属性时，可通过 label 指定
	 */
	label?: string;
	/**
	 * 当搜索项 key 不为 prop 属性时，可通过 key 指定
	 */
	key?: string;
	/**
	 * 搜索项排序（从大到小）
	 */
	order?: number;
	/**
	 * 搜索项所占用的列数，默认为1列
	 */
	span?: number;
	/**
	 * 搜索字段左侧偏移列数
	 */
	offset?: number;
	/**
	 * 插槽名称
	 */
	slot?: string;
	/**
	 * 搜索项默认值
	 */
	defaultValue?: string | number | boolean | any[];
};

/**
 * expand：可展开的按钮
 */
export type GTableColumnType = "expand" | "image" | "date" | "time" | "dateTime" | "submitInfo" | "product" | "location" | "print";

export type GTableColumnDateFormat =
	| "YYYY-MM-DD HH:mm:ss"
	| "YYYY-MM-DD HH:mm"
	| "YYYY-MM-DD"
	| "YYYY-MM"
	| "YYYY"
	| "MM"
	| "DD"
	| "MM-DD"
	| "HH:mm:ss"
	| "HH:mm"
	| "HH"
	| "mm:ss"
	| "mm"
	| "ss";

export type GTableColumnCtx<T = any> = Partial<Omit<TableColumnCtx<T>, "order" | "type" | "prop" | "sortable" | "_children">> & {
	/**
	 * 内部的计算属性，一般不做使用
	 * @description GTable
	 */
	multiOrder?: "" | "ascending" | "descending";
	/**
	 * 列ID
	 * @description GTable
	 */
	columnID?: number;
	/**
	 * 排序
	 * @description GTable
	 */
	order?: number;
	/**
	 * 排序字段
	 * @description GTable
	 */
	sortableField?: string;
	/**
	 * 禁用排序配置
	 * @description GTableColumnSetting
	 */
	disabledSortable?: boolean;
	/**
	 * 合并行字段
	 * @description GTable
	 */
	spanProp?: string;
	/**
	 * 纯搜索
	 * @description GTable
	 */
	pureSearch?: boolean;
	/**
	 * 搜索项配置
	 * @description GTable
	 */
	search?: GTableSearchColumnCtx;
	/** 上面所有属性，全部非 GTableColumns props，均为计算属性 */
	/**
	 * 对应列的类型
	 */
	type?: GTableColumnType;
	/**
	 * 字段名称
	 */
	prop?: string;
	/**
	 * 是否显示在表格当中，默认 true
	 */
	show?: boolean;
	/**
	 * 对应列是否可以排序，
	 * 默认为 'custom' 模式，即远程排序，
	 * 需要监听 Table 的 sort-change 事件
	 */
	sortable?: boolean;
	/**
	 * 小页面的宽度，如果为空，则继承默认宽度
	 */
	smallWidth?: string | number;
	/**
	 * 自适应宽度
	 */
	autoWidth?: boolean;
	/**
	 * 插槽名称
	 */
	slot?: string;
	/**
	 * 表格头部插槽名称
	 */
	headerSlot?: string;
	/**
	 * 自定义表头内容渲染（tsx语法）
	 */
	headerRender?: ({ column, $index }: { column: TableColumnCtx<any>; $index: number } & GTableDefaultSlotsResult) => VNode[];
	/**
	 * 自定义单元格内容渲染（tsx语法）
	 */
	render?: ({ row, column, $index }: { row: any; column: GTableColumnCtx; $index: number } & GTableDefaultSlotsResult) => VNode[];
	/**
	 * 多级表头
	 */
	_children?: GTableColumnCtx<T>[];
	/**
	 * 复制
	 */
	copy?: boolean;
	/**
	 * 是否为 Link Button
	 */
	link?: boolean;
	/**
	 * 点击事件，优先级最高
	 */
	click?: ({ row, $index }: { row: T; $index?: number } & GTableDefaultSlotsResult) => void;
	/**
	 * 点击Emits事件回调
	 */
	clickEmit?: string;
	/**
	 * 显示时间格式化字符串，默认 false
	 */
	dateFix?: boolean;
	/**
	 * 显示在页面中的日期格式
	 */
	dateFormat?: GTableColumnDateFormat;
	/**
	 * 是否是标签展示，默认 false
	 */
	tag?: boolean;
	/**
	 * 枚举类型（渲染值的字典）
	 */
	enum?: GTableEnumColumnType;
	/**
	 * 枚举字典，字段名称
	 */
	fieldNames?: { label: string; value: string };
	/**
	 * 数据删除字段，如果为 true 会显示遮罩层
	 */
	dataDeleteField?: string;
	/**
	 * 提交信息字段
	 */
	submitInfoField?: { submitClerkName?: string; submitTime?: string };
	/**
	 * 商品字段
	 */
	productField?: { productName?: string; productSpecName?: string; productBarCode?: string; productUnit?: string };
	/**
	 * 货位字段
	 */
	locationField?: { locationName?: string; locationCode?: string; locationType?: string; locationTypeKey?: string };
	/**
	 * 打印字段
	 */
	printField?: { printTimes?: string; printClerkName?: string; lastPrintTime?: string };
};
