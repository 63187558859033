import { computed, defineComponent, nextTick, onMounted, reactive, ref, watch, watchEffect } from "vue";
import { Notice } from "@gejia-element-plus/icons-vue";
import { addUnit, consoleWarn, definePropType, makeSlots, useExpose, useRender } from "@gejia-element-plus/utils";

export default defineComponent({
	name: "GNoticeBar",
	props: {
		/** @description 高度 */
		height: {
			type: [String, Number],
			default: "20px",
		},
		/** @description 动画秒数，默认根据 100px 1秒自动计算 */
		duration: Number,
		/** @description 重复次数，默认根据内容自动计算  */
		repeat: {
			type: Number,
			// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
			validator: (val: number) => {
				if (val < 1) {
					consoleWarn("GNoticeBar", "'repeat' 属性不能小于1。");
					return false;
				}
				return true;
			},
		},
		/** @description 多个重复内容的右边距 */
		gap: {
			type: Number,
			default: 50,
		},
		/** @description 暂停动画 */
		paused: Boolean,
		/** @description 鼠标悬浮暂停动画 */
		hoverPaused: {
			type: Boolean,
			default: true,
		},
		/** @description 反向动画 */
		reverse: Boolean,
		/** @description 通知消息 */
		data: {
			type: definePropType<string[]>(Array),
			// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
			default: () => [],
		},
	},
	slots: makeSlots<{
		/** @description 默认内容插槽 */
		default: never;
	}>(),
	setup(props, { slots, expose }) {
		const state = reactive({
			/** 容器宽度 */
			containerWidth: 0,
			duration: 0,
			repeat: 0,
			paused: false,
		});

		const elementRef = ref<HTMLElement>();
		const contentElementRef = ref<HTMLElement>();

		const handleMouseEnter = (): void => {
			if (!props.hoverPaused) return;
			state.paused = true;
		};

		const handleMouseLeave = (): void => {
			if (!props.hoverPaused) return;
			state.paused = false;
		};

		const calcData = (): void => {
			if (contentElementRef.value) {
				const contentWidth = contentElementRef.value.scrollWidth + props.gap;
				if (state.containerWidth > contentWidth) {
					state.repeat = Math.ceil(state.containerWidth / contentWidth) + 1;
					state.duration = contentWidth / 100;
				} else {
					// 超出默认等于2
					state.repeat = 2;
					state.duration = contentWidth / 100;
				}
			}
		};

		onMounted(() => {
			watch(
				() => [props.data, slots.default],
				() => {
					nextTick(() => calcData());
				},
				{
					deep: true,
				}
			);

			watchEffect(async () => {
				const element = elementRef.value;
				if (element) {
					const observer = new ResizeObserver((entries) => {
						for (const entry of entries) {
							const { width } = entry.contentRect;
							state.containerWidth = width;
							calcData();
						}
					});

					observer.observe(element);

					return (): void => {
						observer.disconnect();
					};
				}
			});
		});

		useRender(() => (
			<div class="g-notice-bar" style={{ "--height": addUnit(props.height) }} onMouseenter={handleMouseEnter} onMouseleave={handleMouseLeave}>
				<el-icon>
					<Notice />
				</el-icon>
				<div ref={elementRef} class="g-notice-bar__container">
					<div class="g-notice-bar__warp__hidden" ref={contentElementRef}>
						{slots.default ? slots.default() : props.data.map((item) => <span>{item}</span>)}
					</div>
					<div class="g-notice-bar__warp">
						{Array.from({ length: props.repeat ?? state.repeat }).map(() => (
							<div
								class={[
									"g-notice-bar__content",
									props.paused || state.paused ? "animation-paused" : "",
									props.reverse ? "animation-reverse" : "",
								]}
								style={{
									"padding-right": addUnit(props.gap),
									"animation-duration": `${props.duration ?? state.duration}s`,
								}}
							>
								{slots.default ? slots.default() : props.data.map((item) => <span>{item}</span>)}
							</div>
						))}
					</div>
				</div>
			</div>
		));

		return useExpose(expose, {
			/** @description 暂停动画 */
			paused: computed(() => state.paused),
		});
	},
});
