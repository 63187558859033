import type { Plugin } from "vue";
import { GAvatar } from "@gejia-element-plus/components/avatar";
import { GButton } from "@gejia-element-plus/components/button";
import { GCarNumber } from "@gejia-element-plus/components/carNumber";
import { GContextMenu } from "@gejia-element-plus/components/contextMenu";
import { GDialog } from "@gejia-element-plus/components/dialog";
import { GDrawer } from "@gejia-element-plus/components/drawer";
import { GForm, GFormItem } from "@gejia-element-plus/components/form";
import { GFormItemTip } from "@gejia-element-plus/components/formItemTip";
import { GIcon } from "@gejia-element-plus/components/icon";
import { GIconSelector } from "@gejia-element-plus/components/iconSelector";
import { GImage } from "@gejia-element-plus/components/image";
import { GInputDialogPage } from "@gejia-element-plus/components/inputDialogPage";
import { GLayoutGrid, GLayoutGridItem } from "@gejia-element-plus/components/layoutGrid";
import { GModuleMenu } from "@gejia-element-plus/components/moduleMenu";
import { GNoticeBar } from "@gejia-element-plus/components/noticeBar";
import { GPrintButton } from "@gejia-element-plus/components/printButton";
import { GSelect, GSelectOption } from "@gejia-element-plus/components/select";
import { GSelectPage } from "@gejia-element-plus/components/selectPage";
import { GSelectV2 } from "@gejia-element-plus/components/selectV2";
import { GTable, GTableColumn } from "@gejia-element-plus/components/table";
import { GTag } from "@gejia-element-plus/components/tag";
import { GText } from "@gejia-element-plus/components/text";
import { GTree } from "@gejia-element-plus/components/tree";
import { GTreeSelect } from "@gejia-element-plus/components/treeSelect";
import { GUpload } from "@gejia-element-plus/components/upload";
import { GUploadImage } from "@gejia-element-plus/components/uploadImage";
import { GUploadImages } from "@gejia-element-plus/components/uploadImages";

export default [
	GAvatar,
	GButton,
	GCarNumber,
	GContextMenu,
	GDialog,
	GDrawer,
	GForm,
	GFormItem,
	GFormItemTip,
	GIcon,
	GIconSelector,
	GImage,
	GInputDialogPage,
	GLayoutGrid,
	GLayoutGridItem,
	GModuleMenu,
	GNoticeBar,
	GPrintButton,
	GSelect,
	GSelectOption,
	GSelectPage,
	GSelectV2,
	GTable,
	GTableColumn,
	GTag,
	GText,
	GTree,
	GTreeSelect,
	GUpload,
	GUploadImage,
	GUploadImages,
] as Plugin[];
