import { defineComponent, inject, reactive, ref } from "vue";
import { ElInputNumber, ElMessage } from "element-plus";
import { isArray, isString } from "lodash-unified";
import { useRoute } from "vue-router";
import XLSX from "xlsx-js-style";
import type { PageInput } from "./page.type";
import type { GTableColumnCtx, GTableColumnDateFormat, GTableEnumColumnCtx } from "./table.type";
import { enumMapKey, tableStateKey } from "./useTable";
import { GDialog, type GDialogInstance } from "@gejia-element-plus/components/dialog";
import { GForm, type GFormInstance, GFormItem } from "@gejia-element-plus/components/form";
import { GejiaApp } from "@gejia-element-plus/settings";
import { definePropType, useExpose, useRender, withDefineType } from "@gejia-element-plus/utils";

export default defineComponent({
	name: "GTableExportDialog",
	props: {
		/** @description 搜索参数 */
		searchParam: {
			type: definePropType<PageInput>(Object),
			required: true,
		},
		/** @description 表格数据 */
		data: {
			type: definePropType<any[]>(Array),
			// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
			default: () => [],
		},
		/** @description 导出Api */
		exportApi: {
			// eslint-disable-next-line no-use-before-define
			type: definePropType<
				(
					params?: PageInput & {
						pageTitle?: string;
						columns?: {
							prop: string;
							label: string;
							dateFormat: GTableColumnDateFormat;
							enum: GTableEnumColumnCtx[];
						}[];
					}
				) => Promise<void>
			>(Function),
		},
	},
	setup(props, { expose }) {
		const tableState = inject(tableStateKey);
		const enumMap = inject(enumMapKey);

		const route = useRoute();

		const gDialogRef = ref<GDialogInstance>();
		const gFormRef = ref<GFormInstance>();

		const state = reactive({
			title: "",
			remoteExport: false,
			maxPageIndex: 1,
		});

		const formData = reactive({
			pageIndex: 1,
			pageSize: 1000,
		});

		const formRules = reactive({
			pageIndex: [{ required: true, message: "请输入导出页码", trigger: "blur" }],
			pageSize: [{ required: true, message: "请输入导出行数", trigger: "blur" }],
			columnsIndex: [{ required: true, message: "请选择导出的列", trigger: "change" }],
		});

		const handlePageSizeChange = (value: number): void => {
			formData.pageIndex = 1;
			if (tableState.tablePagination.totalRows === 0) {
				state.maxPageIndex = 0;
			} else {
				state.maxPageIndex = Math.ceil(tableState.tablePagination.totalRows / value);
			}
		};

		const open = (remoteExport: boolean): void => {
			state.remoteExport = remoteExport;
			state.title = `${remoteExport ? "远程" : "本地"}导出Excel选项（共 ${tableState.tablePagination.totalRows} 条）`;
			gDialogRef.value.open(async () => {
				handlePageSizeChange(formData.pageSize);
			});
		};

		const remoteExport = async (): Promise<void> => {
			let requestData: any = {};

			if (props.exportApi) {
				requestData = {
					...props.searchParam,
					...formData,
					columns: withDefineType<
						{
							prop: string;
							label: string;
							dateFormat: GTableColumnDateFormat;
							enum: GTableEnumColumnCtx[];
						}[]
					>([]),
				};
			} else {
				requestData = {
					pageTitle: route.meta?.title?.toString(),
					searchParam: {
						...props.searchParam,
						...formData,
					},
					apiUrl: tableState.responseConfig.url,
					method: tableState.responseConfig.method.toUpperCase(),
					columns: withDefineType<
						{
							prop: string;
							label: string;
							dateFormat: GTableColumnDateFormat;
							enum: GTableEnumColumnCtx[];
						}[]
					>([]),
				};
			}

			tableState.tableColumns.forEach((col: GTableColumnCtx, index: number) => {
				let enumData: GTableEnumColumnCtx[];
				if (isString(col.enum)) {
					enumData = enumMap.get(col.enum);
				} else if (isArray(col.enum)) {
					enumData = col.enum;
				}
				requestData.columns.push({
					prop: col.prop,
					label: col.label,
					dateFormat: col.dateFormat,
					enum: enumData,
				});
			});

			await (props.exportApi ?? GejiaApp.table.exportApi)(requestData);
		};

		const THeaderStyle: XLSX.CellStyle = {
			// font 字体属性
			font: {
				color: { rgb: "ffffff" },
			},
			// fill 颜色填充属性
			fill: {
				fgColor: { rgb: "4472c4" },
			},
			// border style
			border: {
				top: { style: "thin", color: { rgb: "000000" } },
				right: { style: "thin", color: { rgb: "000000" } },
				bottom: { style: "thin", color: { rgb: "000000" } },
				left: { style: "thin", color: { rgb: "000000" } },
			},
		};

		const TBodyStyle = {
			// border style
			border: {
				top: { style: "thin", color: { rgb: "000000" } },
				right: { style: "thin", color: { rgb: "000000" } },
				bottom: { style: "thin", color: { rgb: "000000" } },
				left: { style: "thin", color: { rgb: "000000" } },
			},
		};

		const localExport = (): void => {
			// 获取第一条数据的所有key
			const dataKeys = Object.keys(props.data[0]);

			const exportColumns: { prop: string; dateFormat: GTableColumnDateFormat; enum: GTableEnumColumnCtx[] }[] = [];
			const sheetHeaderData: string[] = ["序号"];
			// 处理表格列，这里要考虑固定的几个type的情况
			tableState.tableColumns.forEach((col: GTableColumnCtx, index: number) => {
				let enumData: GTableEnumColumnCtx[];
				if (isString(col.enum)) {
					enumData = enumMap.get(col.enum);
				} else if (isArray(col.enum)) {
					enumData = col.enum;
				}
				if (col.type === "submitInfo") {
					if (dataKeys.includes(col.submitInfoField?.submitClerkName ?? "submitClerkName")) {
						exportColumns.push({
							prop: col.submitInfoField?.submitClerkName ?? "submitClerkName",
							dateFormat: null,
							enum: enumData,
						});
						sheetHeaderData.push(`${col.label}(职员名称)`);
					}
					exportColumns.push({
						prop: col.submitInfoField?.submitTime ?? "submitTime",
						dateFormat: null,
						enum: enumData,
					});
					sheetHeaderData.push(`${col.label}(时间)`);
				} else if (col.type === "product") {
					exportColumns.push({
						prop: col.productField?.productName ?? "productName",
						dateFormat: null,
						enum: enumData,
					});
					sheetHeaderData.push(`${col.label}(名称)`);
					exportColumns.push({
						prop: col.productField?.productSpecName ?? "productSpecName",
						dateFormat: null,
						enum: enumData,
					});
					sheetHeaderData.push(`${col.label}(规格)`);
					exportColumns.push({
						prop: col.productField?.productBarCode ?? "productCode",
						dateFormat: null,
						enum: enumData,
					});
					sheetHeaderData.push(`${col.label}(编码)`);
					exportColumns.push({
						prop: col.productField?.productBarCode ?? "productOwnCode",
						dateFormat: null,
						enum: enumData,
					});
					sheetHeaderData.push(`${col.label}(自编码)`);
					exportColumns.push({
						prop: col.productField?.productBarCode ?? "productOuterNO",
						dateFormat: null,
						enum: enumData,
					});
					sheetHeaderData.push(`${col.label}(外部编码)`);
					exportColumns.push({
						prop: col.productField?.productBarCode ?? "productBarCode",
						dateFormat: null,
						enum: enumData,
					});
					sheetHeaderData.push(`${col.label}(条码/小单位条码)`);
					exportColumns.push({
						prop: col.productField?.productBarCode ?? "productSupBarCode",
						dateFormat: null,
						enum: enumData,
					});
					sheetHeaderData.push(`${col.label}(中单位条码)`);
					exportColumns.push({
						prop: col.productField?.productBarCode ?? "productBigBarCode",
						dateFormat: null,
						enum: enumData,
					});
					sheetHeaderData.push(`${col.label}(大单位条码)`);
					let unitProp = "";
					if (dataKeys.includes(col.productField?.productUnit ?? "productOrderUnit")) {
						unitProp = col.productField?.productUnit ?? "productOrderUnit";
					} else if (dataKeys.includes("productUnit")) {
						unitProp = "productUnit";
					} else if (dataKeys.includes("unit")) {
						unitProp = "unit";
					}
					if (unitProp) {
						exportColumns.push({
							prop: unitProp,
							dateFormat: col.dateFormat,
							enum: enumData,
						});
						sheetHeaderData.push(`${col.label}(单位)`);
					} else if (dataKeys.includes("stockRelationDesc")) {
						exportColumns.push({
							prop: "stockRelationDesc",
							dateFormat: col.dateFormat,
							enum: enumData,
						});
						sheetHeaderData.push(`${col.label}(换算关系)`);
					}
				} else if (col.type === "location") {
					exportColumns.push({
						prop: col.locationField?.locationName ?? "locationName",
						dateFormat: null,
						enum: enumData,
					});
					sheetHeaderData.push(`${col.label}(名称)`);
					if (dataKeys.includes(col.locationField?.locationCode ?? "locationCode")) {
						exportColumns.push({
							prop: col.locationField?.locationCode ?? "locationCode",
							dateFormat: null,
							enum: enumData,
						});
						sheetHeaderData.push(`${col.label}(编码)`);
					}
					if (dataKeys.includes(col.locationField?.locationType ?? "locationType")) {
						exportColumns.push({
							prop: col.locationField?.locationType ?? "locationType",
							dateFormat: null,
							enum: enumMap.get("LocationTypeEnum"),
						});
						sheetHeaderData.push(`${col.label}(类型)`);
					}
				} else if (col.type === "print") {
					if (dataKeys.includes(col.printField?.printClerkName ?? "printClerkName")) {
						exportColumns.push({
							prop: col.printField?.printClerkName ?? "printClerkName",
							dateFormat: null,
							enum: enumData,
						});
						sheetHeaderData.push(`${col.label}(职员名称)`);
					}
					exportColumns.push({
						prop: col.printField?.printTimes ?? "printTimes",
						dateFormat: null,
						enum: enumData,
					});
					sheetHeaderData.push(`${col.label}(次数)`);
					if (dataKeys.includes(col.printField?.lastPrintTime ?? "lastPrintTime")) {
						exportColumns.push({
							prop: col.printField?.lastPrintTime ?? "lastPrintTime",
							dateFormat: null,
							enum: enumData,
						});
						sheetHeaderData.push(`${col.label}(时间)`);
					}
				} else {
					let dateFormat: GTableColumnDateFormat;
					if (col.type === "date" || col.type === "time" || col.type === "dateTime") {
						switch (col.type) {
							case "date":
								dateFormat = "YYYY-MM-DD";
								break;
							case "time":
								dateFormat = "HH:mm:ss";
								break;
							case "dateTime":
								dateFormat = "YYYY-MM-DD HH:mm:ss";
								break;
						}
						if (col.dateFormat) {
							dateFormat = col.dateFormat;
						}
					}
					exportColumns.push({
						prop: col.prop,
						dateFormat,
						enum: enumData,
					});
					sheetHeaderData.push(col.label);
				}
			});

			// 处理表格数据
			const pageStart = (formData.pageIndex - 1) * formData.pageSize;
			const pageEnd = pageStart + formData.pageSize;
			const sheetBodyData = props.data.slice(pageStart, pageEnd).map((row) => {
				return exportColumns.map((col): XLSX.CellObject => {
					// 判断是否为枚举
					if (col.enum?.length > 0) {
						return {
							v: col.enum.find((f) => f.value == row[col.prop])?.label ?? "",
							t: "s",
							s: TBodyStyle,
						};
					} else if (col.dateFormat) {
						return {
							v: row[col.prop] ?? "",
							t: "d",
							z: col.dateFormat,
							s: TBodyStyle,
						};
					} else {
						return {
							v: row[col.prop] ?? "",
							t: "s",
							s: TBodyStyle,
						};
					}
				});
			});

			// 组装 Excel 数据
			const sheetData: XLSX.CellObject[][] = [
				sheetHeaderData.map((m): XLSX.CellObject => {
					return {
						v: m ?? "",
						t: "s",
						s: THeaderStyle,
					};
				}),
				...sheetBodyData.map((item, index) => [
					{
						v: index + 1,
						t: "n",
						s: TBodyStyle,
					} as XLSX.CellObject,
					...item,
				]),
			];

			// 创建虚拟的 workbook
			const workbook = XLSX.utils.book_new();

			// 创建虚拟的 sheet
			const sheet = XLSX.utils.aoa_to_sheet(sheetData);

			// 设置表头筛选
			sheet["!autofilter"] = { ref: sheet["!ref"] };

			// 将工作表添加到工作簿
			XLSX.utils.book_append_sheet(workbook, sheet);

			// 导出
			XLSX.writeFile(workbook, `${route.meta?.title?.toString()}-${new Date().getTime()}.xlsx`, {
				bookSST: true,
				bookType: "xlsx",
			});
		};

		const handleExportExcel = (): void => {
			gDialogRef.value.close(async () => {
				await gFormRef.value.validateScrollToField();
				if (state.remoteExport) {
					await remoteExport();
				} else {
					if (props.data?.length === 0) {
						ElMessage.warning("不存在任何数据，不能导出Excel！");
						return;
					}
					localExport();
				}
			});
		};

		useRender(() => (
			<GDialog
				ref={gDialogRef}
				class="g-table__export-excel-dialog"
				title={state.title}
				onConfirmClick={handleExportExcel}
				showFullscreen={false}
				closeButtonText="关闭"
				confirmButtonText="导出"
				width="400px"
			>
				<GForm ref={gFormRef} model={formData} rules={formRules}>
					<GFormItem label="行数" prop="pageSize">
						<ElInputNumber
							vModel={formData.pageSize}
							min={100}
							max={
								tableState.tablePagination.totalRows >= 10000
									? 10000
									: tableState.tablePagination.totalRows < 100
										? 100
										: tableState.tablePagination.totalRows
							}
							onChange={handlePageSizeChange}
						/>
					</GFormItem>
					<GFormItem label="页码" prop="pageIndex">
						<ElInputNumber vModel={formData.pageIndex} min={1} max={state.maxPageIndex} />
					</GFormItem>
				</GForm>
			</GDialog>
		));

		return useExpose(expose, {
			/** @description 打开弹窗 */
			open,
		});
	},
});
